import React, { useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import Loading from "../../components/Loading";
import { TextField, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import AlertService from "../../services/Alert.services";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import VehiculeService from "../../services/Vehicule.services";
import { IoIosSave } from "react-icons/io";

function AddAlert() {
  const [vehiculeData, setVehiculeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();

  const checkValidity = (state) => {
    if (!dayjs(state.fields.Date, "YYYY-M-D").isValid()) return false;
    if (state.fields.Type.length === 0) return false;
    if (state.fields.matricule[0].length === 0) return false;
    return true;
  };

  const inputReducer = (state, action) => {
    let newState = {};
    if (action.type === "DATE") {
      const date = dayjs(action.payload, "DD/MM/YYYY").format("YYYY-M-D");
      newState = {
        ...state,
        fields: { ...state.fields, Date: date },
      };
    }

    if (action.type === "TYPE") {
      newState = {
        ...state,
        fields: { ...state.fields, Type: action.payload },
      };
    }

    if (action.type === "VEHICULE") {
      newState = {
        ...state,
        fields: { ...state.fields, matricule: [action.payload] },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [alertData, dispatchAlertData] = useReducer(inputReducer, {
    fields: {
      Type: "",
      Date: dayjs(new Date()).format("YYYY-M-D"),
      matricule: [""],
      Dashboard: [JSON.parse(localStorage.getItem("dash"))],
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const _vehiculeService = new VehiculeService();
      const res = await _vehiculeService.GetAll();
      setVehiculeData(res.data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = JSON.stringify({
      records: [alertData],
    });
    const _alertService = new AlertService();
    await _alertService.Add(body);
    setIsLoading(false);
    history.push(`/alerts`);
    toast.success("Alert Ajouté!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Loading isOn={isLoading} />
      {vehiculeData && !isLoading && (
        <form className="form">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label className="label">Vehicule</label>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => {
                  const vehicule = vehiculeData.find(
                    (vehicule) => vehicule.id === option
                  ).fields;
                  return `${vehicule.NumMatricule} - ${vehicule.Type} - ${vehicule.Marque} - ${vehicule.Modele}`;
                }}
                value={alertData?.fields.matricule[0] || null}
                options={vehiculeData.map((vehicule) => vehicule.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Vehicule" />
                )}
                onChange={(e, value) =>
                  value &&
                  dispatchAlertData({
                    type: "VEHICULE",
                    payload: value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <label className="label">Date</label>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(alertData.fields.Date)}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      value.isValid() &&
                      dispatchAlertData({
                        type: "DATE",
                        payload: value,
                      })
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <label className="label">Type</label>
              <FormControl fullWidth>
                <InputLabel id="type-id">Type</InputLabel>
                <Select
                  labelId="type-id"
                  id="type"
                  value={alertData?.fields.Type}
                  label="Alert"
                  onChange={(e) =>
                    dispatchAlertData({
                      type: "TYPE",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Assurance">Assurance</MenuItem>
                  <MenuItem value="Visite technique">Visite Technique</MenuItem>
                  <MenuItem value="Vignette">Vignette</MenuItem>
                  <MenuItem value="Vidange">Vidange</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      )}

      <div className={`${isLoading ? "button-loading" : "send-button"}`}>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          endIcon={<IoIosSave />}
          disabled={!isValid}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export default AddAlert;
