import React, { useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import Loading from "../../components/Loading";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CircuitService from "../../services/Circuit.services";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { IoIosSave } from "react-icons/io";

function AddCircuit() {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();

  const checkValidity = (state) => {
    if (state.fields.trajet.length == 0) return false;
    if (state.fields.Price <= 0) return false;
    return true;
  };

  const inputReducer = (state, action) => {
    let newState = {};

    if (action.type === "TRAJET") {
      newState = {
        ...state,
        fields: { ...state.fields, trajet: action.payload },
      };
    }

    if (action.type === "PRICE") {
      newState = {
        ...state,
        fields: { ...state.fields, Price: parseInt(action.payload) },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [circuitData, dispatchCircuitData] = useReducer(inputReducer, {
    fields: {
      trajet: "",
      Price: 0,
      Dashboard: [JSON.parse(localStorage.getItem("dash"))],
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = JSON.stringify({
      records: [circuitData],
    });
    const _circuitService = new CircuitService();
    await _circuitService.Add(body);
    setIsLoading(false);
    history.push(`/circuits`);
    toast.success("Circuit Ajouté!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Loading isOn={isLoading} />
      {!isLoading && (
        <form className="form">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label className="label">Trajet</label>
              <TextField
                label="Trajet"
                type="text"
                fullWidth
                value={circuitData.fields.trajet}
                onChange={(e) =>
                  dispatchCircuitData({
                    type: "TRAJET",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <label className="label">Prix de base</label>
              <TextField
                label="Prix"
                fullWidth
                type="number"
                value={circuitData.fields.Price || ""}
                onChange={(e) =>
                  dispatchCircuitData({
                    type: "PRICE",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </form>
      )}

      <div className={`${isLoading ? "button-loading" : "send-button"}`}>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          endIcon={<IoIosSave />}
          disabled={!isValid}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export default AddCircuit;
