import React, { useRef } from "react";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { DateTimeField, TimeField, DateField } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Grid,
  Button,
} from "@mui/material";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";

import "./Filter.scss";
import { useState } from "react";

const Start = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Début</label>
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField format="DD/MM/YYYY" inputRef={innerRef} />
      </LocalizationProvider>
    </FormControl>
  </Grid>
);

const End = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Fin</label>
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField format="DD/MM/YYYY" inputRef={innerRef} />
      </LocalizationProvider>
    </FormControl>
  </Grid>
);

const Date = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Date</label>
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField format="DD/MM/YYYY" inputRef={innerRef} />
      </LocalizationProvider>
    </FormControl>
  </Grid>
);

const Matricule = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Vehicule</label>
    <TextField
      fullWidth
      id="outlined-number"
      label="Vehicule"
      inputRef={innerRef}
    />
  </Grid>
);

const Route = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Trajet</label>
    <TextField
      fullWidth
      id="outlined-number"
      label="Trajet"
      inputRef={innerRef}
    />
  </Grid>
);

const Client = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Client</label>
    <TextField
      fullWidth
      id="outlined-number"
      label="Client"
      inputRef={innerRef}
    />
  </Grid>
);

const Email = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Email</label>
    <TextField fullWidth label="Email" type="email" inputRef={innerRef} />
  </Grid>
);

const Payment = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Payement</label>
    <FormControl fullWidth>
      <InputLabel id="payment-id">Payement</InputLabel>
      <Select
        labelId="payment-id"
        id="payement"
        label="Payement"
        inputRef={innerRef}
      >
        <MenuItem value="payer">Payer</MenuItem>
        <MenuItem value="partial">Partielle</MenuItem>
        <MenuItem value="non payer">Non Payer</MenuItem>
      </Select>
    </FormControl>
  </Grid>
);

const VehiculeType = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Type</label>
    <FormControl fullWidth>
      <InputLabel id="type-id">Type</InputLabel>
      <Select labelId="type-id" id="type" label="type" inputRef={innerRef}>
      
      <MenuItem value="bus">voiture</MenuItem>
        <MenuItem value="bus">Bus</MenuItem>
        <MenuItem value="4*4">4*4</MenuItem>
        <MenuItem value="minibus">Mini Bus</MenuItem>
      </Select>
    </FormControl>
  </Grid>
);

const Marque = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Marque</label>
    <TextField
      fullWidth
      id="outlined-number"
      label="Marque"
      type="text"
      inputRef={innerRef}
    />
  </Grid>
);

const Chauffeur = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Chauffeur</label>
    <TextField
      fullWidth
      id="outlined-number"
      label="Chauffeur"
      type="text"
      inputRef={innerRef}
    />
  </Grid>
);

const CarteIdentite = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Carte Identité</label>
    <TextField
      fullWidth
      id="outlined-number"
      label="Carte Identité"
      inputRef={innerRef}
    />
  </Grid>
);

const Tel = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Numero Telephone</label>
    <TextField
      fullWidth
      id="outlined-number"
      label="Num Tel"
      inputRef={innerRef}
    />
  </Grid>
);

const AlertType = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Type</label>
    <FormControl fullWidth>
      <InputLabel id="type-id">Type</InputLabel>
      <Select labelId="type-id" id="type" label="Alert" inputRef={innerRef}>
        <MenuItem value="" disabled>
          Type
        </MenuItem>
        <MenuItem value="Assurance">Assurance</MenuItem>
        <MenuItem value="Visite technique">Visite Technique</MenuItem>
        <MenuItem value="Vignette">Vignette</MenuItem>
        <MenuItem value="Vidange">Vidange</MenuItem>
      </Select>
    </FormControl>
  </Grid>
);

const MaintenanceType = ({ innerRef }) => (
  <Grid item xs={3}>
    <label className="label">Type</label>
    <FormControl fullWidth>
      <InputLabel id="type-id">Type</InputLabel>
      <Select labelId="type-id" id="type" label="Type" inputRef={innerRef}>
        <MenuItem value="Reparation">Reparation</MenuItem>
        <MenuItem value="Vidange">Vidange</MenuItem>
      </Select>
    </FormControl>
  </Grid>
);

function Filter(props) {
  const [key, setKey] = useState(Math.random().toString());
  const startRef = useRef();
  const endRef = useRef();
  const dateRef = useRef();
  const matriculeRef = useRef();
  const routeRef = useRef();
  const paymentRef = useRef();
  const clientRef = useRef();
  const vehiculeTypeRef = useRef();
  const marqueRef = useRef();
  const telRef = useRef();
  const emailRef = useRef();
  const alertTypeRef = useRef();
  const chauffeurRef = useRef();
  const carteIdentiteRef = useRef();
  const maintenanceTypeRef = useRef();

  const handleFilter = () => {
    let newData = [...props.data];
    if (props.Task) {
      if (startRef.current.value) {
        newData = newData.filter((d) => {
          return dayjs(d.fields.TaskStart).isSameOrAfter(
            dayjs(startRef.current.value, "DD/MM/YYYY").toISOString()
          );
        });
      }

      if (endRef.current.value)
        newData = newData.filter((d) =>
          dayjs(d.fields.TaskEnd).isSameOrBefore(
            dayjs(endRef.current.value, "DD/MM/YYYY").toISOString()
          )
        );

      if (matriculeRef.current.value)
        newData = newData.filter((d) => {
          console.log(d);
          return d.fields.NumMatricule[0].includes(matriculeRef.current.value);
        });

      if (routeRef.current.value)
        newData = newData.filter((d) =>
          d.fields.RouteName[0].includes(routeRef.current.value)
        );

      if (paymentRef.current.value)
        newData = newData.filter(
          (d) => d.fields.Payment === paymentRef.current.value
        );

      if (clientRef.current.value)
        newData = newData.filter((d) =>
          d.fields.NameClient[0].includes(clientRef.current.value)
        );
    }

    if (props.Vehicule) {
      if (matriculeRef.current.value)
        newData = newData.filter((d) =>
          d.fields.NumMatricule.includes(matriculeRef.current.value)
        );

      if (vehiculeTypeRef.current.value)
        newData = newData.filter(
          (d) => d.fields.Type === vehiculeTypeRef.current.value
        );

      if (marqueRef.current.value)
        newData = newData.filter((d) =>
          d.fields.Marque.includes(marqueRef.current.value)
        );
    }

    if (props.Client) {
      if (clientRef.current.value)
        newData = newData.filter((d) =>
          d.fields.Name.includes(clientRef.current.value)
        );

      if (telRef.current.value)
        newData = newData.filter((d) =>
          d.fields.Tel.includes(telRef.current.value)
        );

      if (emailRef.current.value)
        newData = newData.filter((d) =>
          d.fields.mail.includes(emailRef.current.value)
        );
    }

    if (props.Circuit) {
      if (routeRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.trajet.includes(routeRef.current.value);
        });
    }

    if (props.Alert) {
      console.log(
        dateRef.current.value,
        matriculeRef.current.value,
        alertTypeRef.current.value
      );
      if (dateRef.current.value)
        newData = newData.filter((d) => {
          return dayjs(dateRef.current.value, "DD/MM/YYYY").isSame(
            d.fields.Date
          );
        });

      if (matriculeRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.NumMatricule[0].includes(matriculeRef.current.value);
        });

      if (alertTypeRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.Type === alertTypeRef.current.value;
        });

      console.log(newData);
    }

    if (props.Chauffeur) {
      if (chauffeurRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.Name.includes(chauffeurRef.current.value);
        });

      if (carteIdentiteRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.NumIdentite.includes(carteIdentiteRef.current.value);
        });

      if (telRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.NumTel.includes(telRef.current.value);
        });
    }

    if (props.Maintenance) {
      if (matriculeRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.NumMatricule[0].includes(matriculeRef.current.value);
        });

      if (maintenanceTypeRef.current.value)
        newData = newData.filter((d) => {
          return d.fields.NumMatricule[0].includes(matriculeRef.current.value);
        });

      if (dateRef.current.value)
        newData = newData.filter((d) => {
          return dayjs(dateRef.current.value, "DD/MM/YYYY").isSame(
            d.fields.Date
          );
        });
    }

    props.setFilteredData(newData);
  };

  const handleClear = () => {
    if (props.Task) {
      startRef.current.value = "";
      endRef.current.value = "";
      matriculeRef.current.value = "";
      routeRef.current.value = "";
      paymentRef.current.value = "";
      clientRef.current.value = "";
    }

    if (props.Vehicule) {
      matriculeRef.current.value = "";
      vehiculeTypeRef.current.value = "";
      marqueRef.current.value = "";
    }

    if (props.Client) {
      clientRef.current.value = "";
      telRef.current.value = "";
      emailRef.current.value = "";
    }

    if (props.Circuit) {
      routeRef.current.value = "";
    }

    if (props.Alert) {
      dateRef.current.value = "";
      matriculeRef.current.value = "";
      alertTypeRef.current.value = "";
    }

    if (props.Chauffeur) {
      telRef.current.value = "";
      chauffeurRef.current.value = "";
      carteIdentiteRef.current.value = "";
    }

    if (props.Maintenance) {
      dateRef.current.value = "";
      matriculeRef.current.value = "";
      maintenanceTypeRef.current.value = "";
    }

    props.setFilteredData(null);
    setKey(Math.random().toString());
  };

  return (
    <div className="filter-container" key={key}>
      <Grid container spacing={2}>
        {props.Task && (
          <>
            <Start innerRef={startRef} />
            <End innerRef={endRef} />
            <Matricule innerRef={matriculeRef} />
          
            <Payment innerRef={paymentRef} />
            <Client innerRef={clientRef} />
          </>
        )}

        {props.Vehicule && (
          <>
            <Matricule innerRef={matriculeRef} />
            <VehiculeType innerRef={vehiculeTypeRef} />
            <Marque innerRef={marqueRef} />
          </>
        )}

        {props.Client && (
          <>
            <Client innerRef={clientRef} />
            <Tel innerRef={telRef} />
            <Email innerRef={emailRef} />
          </>
        )}

        {props.Circuit && <Route innerRef={emailRef} />}

        {props.Alert && (
          <>
            <Matricule innerRef={matriculeRef} />
            <AlertType innerRef={alertTypeRef} />
            <Date innerRef={dateRef} />
          </>
        )}

        {props.Chauffeur && (
          <>
            <Chauffeur innerRef={chauffeurRef} />
            <CarteIdentite innerRef={carteIdentiteRef} />
            <Tel innerRef={telRef} />
          </>
        )}

        {props.Maintenance && (
          <>
            <Matricule innerRef={matriculeRef} />
            <MaintenanceType innerRef={maintenanceTypeRef} />
            <Date innerRef={dateRef} />
          </>
        )}
      </Grid>
      <div className="filter-button">
        <div className="search-button">
          <Button
            variant="contained"
            endIcon={<MdFilterAlt />}
            onClick={handleFilter}
          >
            Filtrer
          </Button>
        </div>
        <div className="clear-button">
          <Button
            variant="contained"
            endIcon={<MdFilterAltOff />}
            onClick={handleClear}
          >
            Effacer
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Filter;
