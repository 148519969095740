import React, { useState, useEffect } from "react";

import CustomBarChart from "./charts/Bar";
import DashBox from "./DashBox";
import { IoCalendarOutline, IoPeopleSharp } from "react-icons/io5";
import { BiSolidCar } from "react-icons/bi";
import { PiSteeringWheel } from "react-icons/pi";
import { HiBellAlert } from "react-icons/hi2";
import { FaRoute } from "react-icons/fa";
import AlertServecice from "../../services/Alert.services";
import TaskService from "../../services/Task.services";
import DashboardService from "../../services/Dashboard.services";
import Loading from "../../components/Loading";
import Alert from "./Alert";
import Task from "./Task";

import "./styles.scss";
import "./index.css";
import dayjs from "dayjs";

function Dashboard() {
  const [dashboard, setDashboard] = useState(null);
  const [alerts, setAlerts] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const _alertService = new AlertServecice();
  const _taskService = new TaskService();
  const _dashboardService = new DashboardService();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const dashboardRes = await _dashboardService.GetAll();
      debugger
      setDashboard(dashboardRes.data);
      localStorage.setItem("dash", JSON.stringify(dashboardRes.data[0].id));

      const alertRes = await _alertService.GetAll({
        maxRecords: 10,
        "sort[0][field]": "Date",
        "sort[0][direction]": "asc",
      });
      setAlerts(alertRes.data);
      const taskRes = await _taskService.GetAll({
        fields: ["TaskStart", "TaskEnd", "Payment"],
        maxRecords: 10,
        // filterByFormula: "DATETIME_DIFF(TODAY(), {TaskStart}, 'hours') <= 24",
        "sort[0][field]": "TaskStart",
        "sort[0][direction]": "desc",
      });
      setTasks(taskRes.data);
      const now = dayjs();
      const chartDataRes = await _taskService.GetAll({
        fields: ["Payment"],
        filterByFormula: `OR(MONTH(CREATED_TIME()) = ${now.month()}, MONTH(CREATED_TIME()) = ${now
          .add(1, "month")
          .month()}, MONTH(CREATED_TIME()) = ${now
          .subtract(1, "month")
          .month()}, MONTH(CREATED_TIME()) = ${now
          .subtract(2, "month")
          .month()})`,
      });

      getChartData(chartDataRes.data, now);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getChartData = (data, now) => {
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    const finalData = [
      {
        name: months[now.subtract(1, "month").month()],
        Payer: 0,
        "Non Payer": 0,
        Partielle: 0,
      },
      {
        name: months[now.subtract(2, "month").month()],
        Payer: 0,
        "Non Payer": 0,
        Partielle: 0,
      },
      {
        name: months[now.month()],
        Payer: 0,
        "Non Payer": 0,
        Partielle: 0,
      },
      {
        name: months[now.add(1, "month").month()],
        Payer: 0,
        "Non Payer": 0,
        Partielle: 0,
      },
    ];
    data.forEach((task) => {
      var m;
      const createdMonth = dayjs(task.createdTime).month();
      if (createdMonth === now.subtract(2, "month").month()) m = 0;
      if (createdMonth === now.subtract(1, "month").month()) m = 1;
      if (createdMonth === now.month()) m = 2;
      if (createdMonth === now.add(1, "month").month()) m = 3;
try {
  if (task.fields.Payment === "payer") finalData[m]["Payer"] += 1;

}catch{}
try{
  if (task.fields.Payment === "partial") finalData[m]["Partielle"]+= 1;

}
catch{}
try{

  if (task.fields.Payment === "non payer") finalData[m]["Non Payer"] += 1;
  
}
catch{}
     

    });

    setChartData(finalData);
  };

  const dashboardData = [
    {
      title: "Reservations",
      number: dashboard ? dashboard[0].fields.NumberTasks : 0,
      bgColor: "#0077b6",
      icon: IoCalendarOutline,
      link: "/tasks",
    },
    {
      title: "Clients",
      number: dashboard ? dashboard[0].fields.NumberClients : 0,
      bgColor: "#00b4d8",
      icon: IoPeopleSharp,
      link: "/clients",
    },
    {
      title: "Vehicules",
      number: dashboard ? dashboard[0].fields.NumberVehicules : 0,
      bgColor: "#8338ec",
      icon: BiSolidCar,
      link: "/vehicules",
    },
   
   
    {
      title: "Alerts",
      number: dashboard ? dashboard[0].fields.NumberAlerts : 0,
      bgColor: "#f21b3f",
      icon: HiBellAlert,
      link: "/alerts",
    },
  ];

  return (
    <>
      <Loading isOn={isLoading} />
      {!isLoading && (
        <div className="dashboard-container">
          <div className="dashboard-summary">
            {dashboardData.map((field) => (
              <DashBox
                key={field.title}
                title={field.title}
                number={field.number}
                backgroundColor={field.bgColor}
                Icon={field.icon}
                link={field.link}
              />
            ))}
          </div>
          <div className="dashboard-rest">
            <div className="dashboard-alerts-chart">
              <div className="dashboard-alerts">
                <h2>Les Alerts</h2>
                <div className="alerts-list">
                  {alerts &&
                    alerts.map((alert) => (
                      <Alert
                        key={alert.id}
                        matricule={alert.fields.NumMatricule}
                        date={alert.fields.Date}
                        type={alert.fields.Type}
                      />
                    ))}
                </div>
              </div>

              <div className="dashboard-chart">
                <CustomBarChart data={chartData} />
              </div>
            </div>

            <div className="dashboard-tasks">
              <h2>Les Reservations</h2>
              <div className="tasks-list">
                {tasks &&
                  tasks.map((task) => (
                    <Task
                      key={task.id}
                      start={task.fields.TaskStart}
                      end={task.fields.TaskEnd}
                      payment={task.fields.Payment}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
